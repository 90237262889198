<template>
<div>
</div>
</template>

<script>
export default {
  name: "Element_abonnement"
}
</script>

<style scoped>

</style>